<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 4 }" :wrapperCol="{span: 16}" @finish="onSubmit">

				<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">售后地址信息</h4>

				<a-form-item label="收货人" name="receiverName">
					<a-input style="width: 200px;" v-model:value="modelRef.returnAddress.receiverName" placeholder="请输入收货人"></a-input>
				</a-form-item>

				<a-form-item label="收货人电话" name="receiverPhone">
					<a-input style="width: 200px;" v-model:value="modelRef.returnAddress.receiverPhone" placeholder="请输入收货人电话"></a-input>
				</a-form-item>

        <a-form-item label="收货人地址" name="receiverAddress">
          <a-input style="width: 200px;" v-model:value="modelRef.returnAddress.receiverAddress" placeholder="请输入收货人地址"></a-input>
        </a-form-item>

				<h4 style="margin-top: 40px;padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">收货设置</h4>
        <a-form-item label="自动收货时间" name="signTime">
          <a-input style="width: 200px;" v-model:value="modelRef.signTime" placeholder="请输入自动收货时间"></a-input>
          <span class="ui-min">天</span>
        </a-form-item>

        <h4 style="margin-top: 40px;padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">售后设置</h4>
        <a-form-item label="可退时间" name="unPayTime">
          收货后
          <a-input style="width: 200px;" v-model:value="modelRef.unPayTime" placeholder="请输入可退时间"></a-input>
          <span class="ui-min">天</span> 不可退
        </a-form-item>
				
				<a-row>
					<a-col :offset="6" style="margin-top: 40px;">
						<a-button v-permission="['mall_order_setting_submit']" type="primary" html-type="submit">提交</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
import {getOrderSettingDetail, updateOrderSetting} from "../../../../service/modules/mall";

  export default {
		data() {
			return {
				loading: false,
				modelRef: {
          returnAddress: {}
        }
			}
		},
		created() {
      this.getData();
		},
		methods: {
      async getData() {
        this.loading = true;
        try {
          let ret = await getOrderSettingDetail({});
          this.loading = false;
          if(ret.code === 200) {
            this.modelRef = ret.data;
            if(!ret.data.returnAddress) {
              this.modelRef.returnAddress = {};
            }
          }
        } catch(e) {
          this.loading = false;
        }
      },
      async onSubmit() {
        this.loading = true;
        try {
          let ret = await updateOrderSetting({
            returnAddress: this.modelRef.returnAddress,
            signTime: this.modelRef.signTime,
            unPayTime: this.modelRef.unPayTime
          })
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      },
		},
	}
</script>

<style scoped>
.ui-min {
  display: inline-block;
  width: 40px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  background-color: #d7d7d7;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
